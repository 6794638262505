<template>
  <div :class="'home ' + (noticeShow ? 'glo-magbot' : 'glo-magbot0')">
    <div class="weather">
      <div class="content">
        <van-row>
          <van-col span="12" class="weather-text">
              <div><span class="tep">{{weather.airTemp || 0}}℃</span></div>
            </van-col>
            <van-col span="12">
              <div class="block-0">
                <span class="info-title">{{weather.roadState == 1 ? '潮湿' : weather.roadState == 2 ? '积水' :weather.roadState == 3 ? '积雪' : weather.roadState == 4 ? '结冰' :'干燥'}}</span>
                <span class="small">(路面)</span>
              </div>
            </van-col>
        </van-row>
        <van-row>
            <van-col span="8">
                <div><i class="iconfont icon-iconset0469"></i></div>
                {{weather.atmoPressure || 0}} Pa
                <div class="text"><span class="small">气压</span></div>
            </van-col>
            <van-col span="8">
                <div><i class="iconfont icon-shidu"></i></div>
                {{weather.airHumi || 0}}%
                <div class="text"><span class="small">湿度</span></div>
            </van-col>
            <van-col span="8">
                <div><i class="iconfont icon-jiangyuliang"></i></div>
                {{weather.visibility || 0}} m
                <div class="text"><span class="small">能见度</span></div>
            </van-col>
        </van-row>
        <div class="update-time">更新时间：{{weather.devTime || '0000-00-00 00:00:00'}}</div>
      </div>
    </div>
    <div class="relative chart-content">
      <van-circle v-model="currentRate" layer-color="#e2e2e2" :rate="healthVal || 0" :speed="100" :color="healthVal < 45 ? grad_subHealth : grad_health" :stroke-width="85" size="180px">
            <div class="glo-inner-align-0">
              <transition name="circle-text">
                    <div v-if="!time" key="text1">
                        <div class="time">{{nowTime}}</div>
                        <div class="tag">北京时间</div>
                    </div>
                    <div v-else key="text2">
                        <div class="time">{{healthVal || 0}}</div>
                        <div class="tag">综合评估</div>
                    </div>
              </transition>
            </div>
      </van-circle>
    </div>
    <div class="show-status">
      <van-row>
        <van-col span="12" class="border-right right"><i :class="{'fa fa-heartbeat':true,'fa-active':time}" @click="time = true"></i></van-col>
        <van-col span="12" class="left"><i :class="{'fa fa-clock-o':true,'fa-active':!time}" @click="time = false"></i></van-col>
      </van-row>
    </div>
    <!-- 底部 -->
    <div class="info-data">
      <van-row type="flex" align="center">
        <van-col span="12">
            <router-link to="/bridge/newVersion/sensor">
              <div class="block-top block-pub">
                <div class="title">
                  <div>
                    <van-icon name="desktop-o" />
                    <div>传感器</div>
                  </div>
                </div>
                <div class="number">{{total.bridge || 0}}</div>
              </div>
            </router-link>
        </van-col>
        <van-col span="12">
          <div>
            <router-link to="/bridge/newVersion/weather">
              <div class="block-btm block-pub clo1 glo-relative">
                <div class="title">
                    <van-icon name="fire-o" />
                    <div>气象精灵</div>
                </div>
                <div class="number">{{total.melves || 0}}</div>
              </div>
            </router-link>
          </div>
          <van-row>
            <div>
              <router-link to="/bridge/newVersion/water">
                <div class="block-btm block-pub clo3 glo-relative">
                  <div class="title">
                      <van-icon name="bar-chart-o" />
                      <div>水位精灵</div>
                  </div>
                  <div class="number">{{total.wlms || 0}}</div>
                </div>
              </router-link>
            </div>
          </van-row>
        </van-col>
      </van-row>

      <van-row type="flex" align="center">
        <van-col span="24">
          <router-link to="/bridge/newVersion/video">
            <div class="block-btm block-pub clo4 glo-relative">
                <div class="title">
                    <van-icon name="video-o" />
                    <div>智慧相机</div>
                </div>
                <div class="number">{{total.camera || 0}}</div>
            </div>
          </router-link>
        </van-col>
      </van-row>
    </div>

    <tabbar />
  </div>
</template>

<script>
import tabbar from '@/components/bridge/NVtabbar.vue'

export default {
  name: 'Home',
  components:{
      tabbar
  },
  props: ['magbot'],
  data(){
    return{
        projectCode:null,
        time:true,
        nowTime:'00:00',
        currentRate:0,
        grad_subHealth: {
          '0%': '#8ae7d0',
          '100%': '#1E90FF',
        },
        grad_health:{
          '0%': '#1E90FF',
          '100%': '#0BD1C0',
        },
        healthVal:'',
        magbotVal:'',
        weather:{},
        total:{
          bridge:'',
          melves:'',
          wlms:'',
          camera:''
        },
        downTime:null
    }
  },
  // 接收父组件的值
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;   
      }
  },
  methods:{
    // 获取当前时间
    getNowTime(){
      let time = new Date();
      return `${time.getHours()}:${time.getMinutes()<10 ? '0'+time.getMinutes() : time.getMinutes()}`
    },
    // 获取桥梁健康值
    healthInfo(){
      this.$api.BRIDGE.getHealth({
        projectCode:this.projectCode
      }).then( d=>{
        
        this.healthVal = d.briHealth *10;
      })
    },
    // 获取天气预报信息
    Weather(){
      this.$api.NEWBRIDGE.melvesNewData({
        projectCode:this.projectCode
      }).then( d => {
          this.weather = { ...d[0].melvesStatus };
      })
    },
    // 获取网关和传感器数量
    devCountInfo(){
      this.$api.NEWBRIDGE.bhmsCount({projectCode:this.projectCode}).then( d => {
         this.total.bridge = d.total;
      })
      this.$api.NEWBRIDGE.melvesCount({projectCode:this.projectCode}).then( d => {
         this.total.melves = d.total;
      })
      this.$api.NEWBRIDGE.wlmsCount({projectCode:this.projectCode}).then( d => {
         this.total.wlms = d.total;
      })
       this.$api.NEWBRIDGE.cameraCount({projectCode:this.projectCode}).then( d => {
         this.total.camera = d.total;
      })
    },
  },
  mounted(){
    this.projectCode = window.sessionStorage.getItem('projectCode');

    if(this.projectCode){
      this.healthInfo();
      this.Weather();
      this.devCountInfo();
    }
    // 更新当前时间
    this.downTime = setInterval(() => {
      this.nowTime = this.getNowTime();
    }, 1000);
  },
  beforeDestroy(){
    this.downTime ? clearInterval(this.downTime) : void 0;
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
}
</script>

<style lang="scss" scoped>
.block-0{
    display: flex;
    justify-content: center;
    .small{
      font-size: 0.8rem;
      font-weight: lighter;
      display: flex;
      align-items: center;
      padding-left: 10px;
      line-height: 5.5;
    }
}
.info-title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #32465a;
}
.banner{
  img{
    width: 100%;
    height: 12rem;
  }
  .title{
    font-size: 1.2rem;
    font-weight: bold;
    position: absolute;
    top: 0;
    color: #33969c;
    padding-left: 5%;
    padding: 4% 0 0 5%;
    text-shadow: 4px 2px 1px #dedada;
    letter-spacing: 4px;
  }
}
.chart-content{
    padding-top: 8%;
    .time{
      font-size: 2.5rem;
      color: rgb(92, 75, 66);
      margin-bottom: 4%;
      font-weight: bold;
    }
    .tag{color:#a5a5a5;letter-spacing: 2px;font-size: .85rem;}
}
.flex{display: flex;justify-content: left;align-self: center;padding-left: 25%;}
.show-status{
  margin: 4%;
  font-size: 2.4rem;color:#a5a5a5;
  .right{text-align: right;padding-right: 5%;}
  .left{text-align: left;padding-left: 5%;}
  .border-right{border-right: 2px solid rgb(231, 231, 231);}
  .fa-active{color: #0eb9ab;}
}
.weather{
  padding: 2% 4%;
  .content{
    padding: 4%;
    background-color: #87e8e8;
    border-radius: 15px;
    box-shadow: 3px 1px 5px 0px #b1b1b1;
    .weather-icon{
      font-size: 4rem;
      color: #0089ff;
    }
    .weather-text{
        font-size: .95rem;
        line-height: 1.5;
        white-space: nowrap;
        .tep{font-size: 2.4rem;font-weight: bold;}
    }
    .iconfont{font-size: 1.5rem;color: #2faddf;line-height: 1;}
    .text{font-size: .85rem;margin-top: 4%;color: #737373;}
    .icon-iconset0469{color: #650c0c;}
    .icon-shidu{color: #d46d6d;}
    .icon-jiangyuliang{color: #e06d18;}
    .update-time{font-size: .5rem;color: #9a9a9a;text-align: right;padding-top: 4%;}
  }
}
.circle-text{
  position: fixed;left:0;right: 0;
  width: 100%;
}
.circle-text-enter, .circle-text-leave-to
{
  left:0;top: 0;right: 0;
  position: absolute;
  transform:translateX(0px) translateY(0px) scale(0.2);
  opacity:1;
}
.circle-text-enter-active {
  transition: all 0.6s ease;
}
.circle-text-leave-active {
  transition: all .1s ease;
  transform:translateX(0) translateY(0) scale(0) ;
  opacity: 0;
  z-index: 100;
}
.info-data{
  padding:4%;
  .block-pub{
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px #ccc;
  }
  .block-top{
    background-color: #39cfe8;
    height: 8rem;
    margin-right: 20px;
    .van-icon{font-size: 2rem;}
    .title{font-size: .75rem;display: flex;align-items: center;}
    .number{font-size: 3rem;padding-left: 4%;display: flex;align-items: center;}
  }
  .block-btm{
      .van-icon{font-size: 1.5rem;}
      .title{font-size: .55rem;}
      .number{font-size: 2rem;padding-left: 4%;}
  }
  .margin-space{margin: 6% 0 2% 0;}
  .clo1{background-color: #5d96ff;margin-bottom: 10%;}
  .clo3{background-color: #6acc68;}
  .clo4{background-color: #45778a;margin-top: 15px;}
}
.glo-inner-align-0{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>